<template>
    <div
        v-shortkey="['alt']"
        class="outer"
        @shortkey="show_invoice_id = !show_invoice_id"
    >
        <header>
            <div class="back" @click="goBack">
                <svgicon name="triangle" class="triangle" />
                <label>BACK</label>
                <span class="spacer">/</span>
                <span>
                    {{ invoice_number }}
                </span>
            </div>

            <template v-if="!loading && invoice">
                <div v-if="invoice.is_linked" class="link-info">
                    <span>
                        This invoice is fully linked
                    </span>
                    <invoice-status-icon :status="INVOICE_STATUS.PAID" />
                </div>
                <div v-else class="link-info">
                    <span>
                        This invoice is missing some linking
                    </span>
                    <invoice-status-icon
                        :status="INVOICE_STATUS.ERROR"
                        class="icon-error"
                    />
                </div>
            </template>

            <div class="button-container">
                <el-button
                    v-if="can_delete && !loading"
                    slot="reference"
                    type="danger"
                    size="mini"
                    :disabled="loading"
                    @click="deleteInvoice"
                >
                    Delete
                </el-button>

                <el-button
                    size="mini"
                    :disabled="loading"
                    @click="fetchInvoice"
                >
                    Refresh
                </el-button>
            </div>
        </header>

        <loader v-if="loading" />
        <div v-else class="inner">
            <content-block
                v-if="projects.length && sorted_line_items.length"
                title="Line items"
            >
                <invoice-line-item-link-block
                    v-for="line_item in sorted_line_items"
                    :key="line_item.id"
                    :line_item="line_item"
                    :projects="projects"
                    :show_invoice_id="show_invoice_id"
                />
            </content-block>

            <el-alert
                v-else-if="!projects.length"
                style="border-radius: 0;"
                description="This invoice can't be linked, this client doesn't have any assigned projects."
                :closable="false"
            />
            <el-alert
                v-else-if="!sorted_line_items.length"
                style="border-radius: 0;"
                description="Something went wrong. No line items found for this invoice."
                :closable="false"
            />

            <content-block title="Details">
                <invoice-form
                    :invoice="invoice"
                    :show_invoice_id="show_invoice_id"
                />
            </content-block>
        </div>
    </div>
</template>

<script>
import {getInvoice} from '@/api/invoices';

import ContentBlock from '@/components/blocks/ContentBlock';
import Loader from '@/components/generic/Loader';
import InvoiceLineItemLinkBlock from '@/components/blocks/InvoiceLineItemLinkBlock';
import InvoiceStatusIcon from '@/components/generic/InvoiceStatusIcon';

import InvoiceForm from './components/InvoiceForm';

import invoiceMixin from '@/mixins/invoice.mixin';
import {Notification} from 'element-ui';
import {INVOICE_STATUS} from '@/enums';

export default {
    name: 'management-clients-invoice-detail',
    components: {
        InvoiceForm,
        ContentBlock,
        Loader,
        InvoiceLineItemLinkBlock,
        InvoiceStatusIcon,
    },
    mixins: [invoiceMixin],
    data() {
        return {
            loading: true,
            line_items: [],
            show_invoice_id: false,
        };
    },
    computed: {
        INVOICE_STATUS() {
            return INVOICE_STATUS;
        },
        invoice() {
            return this.$store.getters.invoiceWithId(
                this.$route.params.invoice_number
            );
        },
        client() {
            return this.$store.getters.clientWithId(this.invoice?.contact_id);
        },
        invoice_number() {
            if (this.invoice) return this.invoice.invoice_number;
            return this.$route.params.invoice_number;
        },
        projects() {
            if (!this.client) return [];
            if (!this.client.projects) return [];

            return this.client.projects
                .map((project_ref) =>
                    this.$store.getters.projectWithId(project_ref)
                )
                .filter((p) => p);
        },
        sorted_line_items() {
            if (!this.invoice) return [];
            if (!this.line_items) return [];

            return this.line_items.slice(0).sort(function (a, b) {
                if (a.description < b.description) return -1;
                if (a.description > b.description) return 1;
                return 0;
            });
        },
        can_delete() {
            if (!this.invoice) return false;
            return (
                this.invoice.status === 'VOIDED' ||
                this.invoice.status === 'DELETED'
            );
        },
    },
    watch: {
        '$route.params.invoice_number'(val, oldVal) {
            if (val !== oldVal) {
                this.init();
            }
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // Invoice is NOT saved on Firebase - fetch it from API
            if (!this.invoice) {
                await this.fetchInvoice();
            }
            await this.bindLineItems();
        },
        async fetchInvoice() {
            this.loading = true;

            console.log('Fetching invoice...');

            try {
                const result = await getInvoice(this.invoice.id);
                if (result.success) {
                    console.log('Success!');
                    this.loading = false;

                    return result.data;
                } else {
                    throw new Error('Invoice not found.');
                }
            } catch (err) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message:
                        'Something went wrong while fetching the invoice. (100)',
                });

                // invoice not found, show invoice list
                this.$router.replace({
                    name: 'management_invoices',
                });
                return null;
            }
        },
        async bindLineItems() {
            this.loading = true;

            return new Promise((resolve, reject) => {
                this.$bind(
                    'line_items',
                    this.$fire
                        .collection('line_items')
                        .where(
                            'invoice',
                            '==',
                            this.$fire.doc(`invoices/${this.invoice.id}`)
                        ),
                    {maxRefDepth: 0}
                )
                    .then(async () => {
                        if (!this.line_items.length) {
                            console.log('No line items saved - fetching...');
                            await this.fetchInvoice();
                        } else {
                            console.log(
                                'Line items already saved - not updating automatically.'
                            );
                        }

                        resolve();
                    })
                    .catch((e) => {
                        Notification({
                            type: 'error',
                            title: 'Error',
                            message:
                                'Something went wrong while fetching the invoice. (103)',
                        });

                        reject();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        async deleteInvoice() {
            this.loading = true;

            await this.deleteInvoiceAndData();

            this.goBack();
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        justify-content: space-between;

        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
        }

        .back {
            cursor: pointer;
            margin: 10px 0;
            padding: 20px 20px;
            border-radius: 5px;
            background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            @media screen and (max-width: 992px) {
                padding-left: 0;
            }

            .triangle {
                color: $black;
                width: 10px;
                height: 10px;
                margin: 4px 10px 3px 0;
                transform: scaleX(-1) translateX(0);
                filter: FlipH;
                -ms-filter: 'FlipH';
                transition: transform 0.3s ease-in-out 0.1s;
            }

            label {
                cursor: pointer;
                font-weight: 400;
                font-size: 14px;
                text-align: right;
                color: $black;
                text-transform: uppercase;
                margin-right: 4px;
                margin-top: 1px;
                transform: translateX(0);
                transition: transform 0.3s ease-in-out;
            }

            span.spacer {
                margin: 1px 6px 0 6px;
            }

            span {
                font-size: 13px;
                margin-top: 1px;
            }

            span {
                transition: transform 0.3s ease-in-out;
            }

            &:hover {
                .triangle {
                    transform: scaleX(-1) translateX(3px);
                }

                label,
                span {
                    transform: translateX(3px);
                }
            }
        }

        .button-container {
            @media screen and (max-width: 992px) {
                width: 100%;
                display: flex;
                margin-top: 24px;
            }
        }
    }

    .link-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        padding-left: 22px;
        border-left: 1px solid $border-grey-light;

        @media screen and (max-width: 992px) {
            border-left: 0;
            padding-left: 0;
        }

        span {
            font-size: 12px;
            margin-right: 10px;
            padding-bottom: 1px;
        }

        ::v-deep .icon-error svg {
            fill: $red;
        }
    }

    .inner {
        .content-block {
            background: $white;
            margin-bottom: 0;
        }

        .alert {
            margin: 20px;
            width: unset;
        }
    }
}
</style>
